import { Injectable } from "@angular/core";
import { IRomulusPostMessage, NotificationTypes, RomulusMessageTypes } from "../../models/integrations/romulus.model";
import { Logger, LoggerService } from "../../../core/services/logger";
import { BehaviorSubject } from "rxjs";
import { AUDIO_TYPE, AudioService, ConfigService, ElectronService } from "../../../core/services";
import { Router } from "@angular/router";
import { IPC_CHANNELS } from "../../../../../electron-utils/electron.model";
import { UtilsService } from "../utils/utils.service";
import { BrowserNotificationService, NotificationService } from "../../../core/services/notifications";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class RomulusInboxService {

  private log: Logger;
  private _iframe: HTMLIFrameElement = document.createElement('iframe');
  
  private notifications: IRomulusPostMessage[] = [];
  private _notificationsCount: number = 0;

  public notifications$: BehaviorSubject<IRomulusPostMessage[]> = new BehaviorSubject(this.notifications);
  public notificationsCount$: BehaviorSubject<number> = new BehaviorSubject(this._notificationsCount);

  constructor(
    private loggerService: LoggerService,
    private configService: ConfigService,
    private audioService: AudioService,
    private router: Router,
    private electronService: ElectronService,
    private utilsService: UtilsService,
    private browserNotificationService: BrowserNotificationService,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ){
    this.log = loggerService.getLoggerInstance('RomulusInbox');
  }

  public init(token: string) {
    this._iframe.src = `https://chat.romulus.live/api-login?token=${token}`;
    this._iframe.frameBorder = '0';
    this._iframe.style.position = 'absolute';
    this._iframe.hidden = true;
    document.body.appendChild(this._iframe);
    this.attachPostMessage();

    if(this.electronService.isElectron){
      this.electronService.ipcRenderer.on(IPC_CHANNELS.OPEN_INCOMING_MESSAGES_VIEW, (event) => {
        this.router.navigate(['home', 'inbox']);
      });
    }
    this._iframe.addEventListener('error', (err: ErrorEvent) => {
      this.notificationService.error('inbox.frame-error');
    });
  }

  public destroy(): void {
    this._iframe.remove();
    window.removeEventListener('message', this.postMessageCallback);
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  public get notificationsCount(): number {
    return this._notificationsCount;
  }

  public removeNotificationAt(index: number) {
    this.notifications.splice(index, 1);
    this.notifications$.next(this.notifications);
  }

  private attachPostMessage(){
    window.addEventListener('message', this.postMessageCallback);
  }

  private postMessageCallback = (message) => {
    if(message.origin === 'https://chat.romulus.live' && message.data)
      this.processPostMessage(message.data);
  }

  private processPostMessage(message: IRomulusPostMessage): void {
    message.id = `message_${Math.random().toString(16).slice(2)}`;
    if( !this.configService.config.settings.doNotDisturb ){
      this.addNotification(message);
      this.audioService.playAndStop(AUDIO_TYPE.MESSAGE_RECEIVED);
      this.openExternalNotification(message);
    }
    if(this.router.url !== '/home/inbox'){
      this.addNotificationCount();
    }
  }

  private addNotification(message: IRomulusPostMessage) {
    this.notifications.push(message);
    this.notifications$.next(this.notifications);
  }

  private clearAllNotifications(): void {
    this.notifications = [];
    this.notifications$.next(this.notifications);
  }

  private addNotificationCount(): void {
    this._notificationsCount++;
    this.notificationsCount$.next(this._notificationsCount);
  }

  public clearNotificationCount(): void {
    this._notificationsCount = 0;
    this.notificationsCount$.next(this._notificationsCount);
    this.clearAllNotifications();
  }

  private openExternalNotification(message: IRomulusPostMessage): void {
    if(this.electronService.isElectron){
      this.electronService.ipcRenderer.send(IPC_CHANNELS.OPEN_INCOMING_MESSAGE_WINDOW, message.id, JSON.stringify(message));
    } else if(!this.utilsService.isAppVisible){
      this.openBrowserNotification(message);
    }
  }

  private openBrowserNotification(message: IRomulusPostMessage): void {
    let title: string;
    let body: string;
    switch(message.type){
      case RomulusMessageTypes.MESSAGE:
        title = this.translateService.instant('common.notification.incoming-message.new-message', {
          messagedBy: message.payload.data['messagedBy']
        });
        body = message.payload.message;
        break;

      case RomulusMessageTypes.LIVE_CHAT_REQUEST:
        title = this.translateService.instant('common.notification.incoming-message.live-chat.request');
        body = this.translateService.instant('common.notification.incoming-message.live-chat.info');
        break;
    }

    const notification: Notification = this.browserNotificationService.notify(title, {
      body: body,
      badge: 'assets/icons/favicon.png',
      requireInteraction: true
    });
    const callback = () => {
      if(document.visibilityState === 'visible') {
        notification.close();
      }
      document.removeEventListener('visibilitychange', callback);
    }
    document.addEventListener('visibilitychange', callback);
    notification.addEventListener('click', () => {
      window.focus();
      notification.close();
      document.removeEventListener('visibilitychange', callback);
    });
  }
}